import React, { FC } from 'react';
import { Container, ContainerProps } from '@mui/material';
import { AppBar } from 'cms/components/AppBar';
import { BaseLayout } from './BaseLayout';
import { MotionBox } from './MotionBox';

const variants = {
  initial: {},
  enter: {},
  exit: {}
};

const DefaultLayoutCore: FC<ContainerProps> = ({ children }) => {
  return (
    <>
      <AppBar appBar={{ color: 'inherit' }} />
      <MotionBox
        variants={variants}
        initial='initial'
        animate='enter'
        exit='exit'
        height={'100%'}
        paddingTop={13}
      >
        <Container fixed={true}>{children}</Container>
      </MotionBox>
    </>
  );
};

export const DefaultLayout: FC<ContainerProps> = ({ children }) => {
  return (
    <BaseLayout>
      <DefaultLayoutCore>{children}</DefaultLayoutCore>
    </BaseLayout>
  );
};
