import { Box, Grid, Paper } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { VerticalLogo } from 'common/components/VerticalLogo';
import { useRouter } from 'next/dist/client/router';
import { MotionBox } from 'common/components/MotionBox';
import { projectListRoute } from 'project/routes';
import { DefaultLayout } from 'common/components/DefaultLayout';
import { useMeQuery } from 'user/queries/me';
import { useTheme } from '@mui/styles';

const duration = 0.3;
const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: duration }
  }
};

const AuthenticationFormsLayoutCore: FC = ({ children }) => {
  const theme = useTheme();
  const { data } = useMeQuery({
    notifyOnNetworkStatusChange: true
  });
  const router = useRouter();

  useEffect(() => {
    if (data?.me) {
      router.replace(
        (router.query.r as string) || projectListRoute(),
        undefined,
        { scroll: false }
      );
    }
  }, [data?.me]);

  const onLoadAuth = useMemo(() => {
    return !!data?.me;
  }, []);

  if (onLoadAuth) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          minHeight: `calc(100vh - ${theme.spacing(8)})`,
          '&:before': {
            content: '" "',
            display: 'block',
            width: '100%',
            height: 'calc(100% - 72px)',
            position: 'absolute',
            top: 72,
            left: 0,
            opacity: 0.2,
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            ...theme.mixins.makeBackground('gs://maastr_staging/hero.jpg')
          },
          '&:after': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            top: 72,
            left: 0,
            width: '100%',
            height: 'calc(100% - 72px)',
            backdropFilter: 'brightness(0.75)'
          }
        }}
        display={'flex'}
        alignItems={'center'}
      >
        <Grid
          container={true}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ zIndex: 1 }}
        >
          <Grid item={true} xs={12} md={8} lg={6}>
            <MotionBox
              variants={variants}
              initial='initial'
              animate='enter'
              exit='exit'
            >
              <Paper variant={'transparent'}>
                <Box
                  padding={5}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <VerticalLogo />
                  <Box marginTop={8} width={'100%'}>
                    {children}
                  </Box>
                </Box>
              </Paper>
            </MotionBox>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const AuthenticationFormsLayout: FC = ({ children }) => {
  return (
    <DefaultLayout>
      <AuthenticationFormsLayoutCore>{children}</AuthenticationFormsLayoutCore>
    </DefaultLayout>
  );
};
