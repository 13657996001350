import React, { FC } from 'react';
import { useTheme } from '@mui/material';

interface VerticalLogoProps {
  width?: number;
  color?: string;
}

export const VerticalLogo: FC<VerticalLogoProps> = ({ color, width }) => {
  const theme = useTheme();
  return (
    <svg
      width={width || theme.spacing(19)}
      fill={color || theme.palette.text.primary}
      viewBox='0 0 154 101'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M52.6859 50.7703C51.4212 50.7703 50.3975 49.7486 50.3975 48.4865V2.32929C50.3975 1.36768 50.9997 0.526276 51.8428 0.165672C52.7461 -0.194931 53.7097 0.0454713 54.3721 0.766678L76.4734 24.6266L98.5748 0.766678C99.2372 0.0454713 100.201 -0.13483 101.104 0.165672C102.007 0.526276 102.549 1.36768 102.549 2.32929V43.8588C102.549 45.1209 101.526 46.1426 100.261 46.1426C98.9963 46.1426 97.9725 45.1209 97.9725 43.8588V8.21914L78.1596 29.6149C77.7381 30.0957 77.1359 30.3361 76.4734 30.3361C75.811 30.3361 75.2088 30.0957 74.7872 29.6149L55.0345 8.21914V48.4865C55.0345 49.7486 53.9505 50.7703 52.6859 50.7703Z'
        fill='white'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M28.2367 36.9471C26.972 36.9471 25.9482 35.9254 25.9482 34.6633V27.3911C25.9482 26.129 26.972 25.1073 28.2367 25.1073C29.5013 25.1073 30.5251 26.129 30.5251 27.3911V34.6633C30.5853 35.9254 29.5615 36.9471 28.2367 36.9471Z'
          fill='white'
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M40.3421 42.8974C39.0775 42.8974 38.0537 41.8757 38.0537 40.6136V20.7804C38.0537 19.5183 39.0775 18.4966 40.3421 18.4966C41.6068 18.4966 42.6306 19.5183 42.6306 20.7804V40.5535C42.6306 41.8156 41.6068 42.8974 40.3421 42.8974Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M64.43 44.8807C63.1654 44.8807 62.1416 43.859 62.1416 42.5969V32.6803C62.1416 31.4182 63.1654 30.3965 64.43 30.3965C65.6947 30.3965 66.7184 31.4182 66.7184 32.6803V42.5368C66.7184 43.7989 65.6947 44.8807 64.43 44.8807Z'
          fill='white'
        />
      </g>
      <g opacity='0.61'>
        <path
          opacity='0.61'
          d='M88.5179 44.8803C87.2533 44.8803 86.2295 43.8586 86.2295 42.5965V35.3243C86.2295 34.0622 87.2533 33.0405 88.5179 33.0405C89.7826 33.0405 90.8063 34.0622 90.8063 35.3243V42.5364C90.8666 43.7985 89.8428 44.8803 88.5179 44.8803Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M112.667 36.9473C111.403 36.9473 110.379 35.9256 110.379 34.6635V17.4747C110.379 16.2126 111.403 15.1909 112.667 15.1909C113.932 15.1909 114.956 16.2126 114.956 17.4747V34.6034C114.956 35.9256 113.932 36.9473 112.667 36.9473Z'
          fill='white'
        />
      </g>
      <g opacity='0.69'>
        <path
          opacity='0.69'
          d='M124.711 25.1073C123.447 25.1073 122.423 24.0856 122.423 22.8235V12.9069C122.423 11.6448 123.447 10.623 124.711 10.623C125.976 10.623 127 11.6448 127 12.9069V22.8235C127 24.0255 125.976 25.1073 124.711 25.1073Z'
          fill='white'
        />
      </g>
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M76.474 47.4647C75.2093 47.4647 74.1855 46.443 74.1855 45.1809V41.2142C74.1855 39.9521 75.2093 38.9304 76.474 38.9304C77.7386 38.9304 78.7624 39.9521 78.7624 41.2142V45.1809C78.8226 46.443 77.7386 47.4647 76.474 47.4647Z'
          fill='white'
        />
      </g>
      <g opacity='0.54'>
        <path
          opacity='0.54'
          d='M76.474 13.8686C75.2093 13.8686 74.1855 12.8469 74.1855 11.5848V7.61817C74.1855 6.35606 75.2093 5.33435 76.474 5.33435C77.7386 5.33435 78.7624 6.35606 78.7624 7.61817V11.5848C78.8226 12.8469 77.7386 13.8686 76.474 13.8686Z'
          fill='white'
        />
      </g>
      <path
        d='M14.1461 100.009V87.4721L9.16666 100.009H7.92181L2.8858 87.4721V100.009H0V83.745H4.01748L8.48765 94.9262L12.9012 83.745H16.9187V100.009H14.1461Z'
        fill='white'
      />
      <path
        d='M43.8522 100.009L42.6639 96.8462H35.1948L34.0066 100.009H30.7812L37.1753 83.745H40.7401L47.1341 100.009H43.8522ZM38.9294 86.5685L35.987 94.305H41.8718L38.9294 86.5685Z'
        fill='white'
      />
      <path
        d='M72.2585 100.009L71.0702 96.8462H63.6011L62.4128 100.009H59.1875L65.5815 83.745H69.1463L75.4838 100.009H72.2585ZM67.3356 86.5685L64.3932 94.305H70.278L67.3356 86.5685Z'
        fill='white'
      />
      <path
        d='M87.9316 97.6933L89.5726 95.491C90.6477 96.6768 92.4584 97.7498 94.7218 97.7498C97.0417 97.7498 97.9471 96.6204 97.9471 95.491C97.9471 92.1027 88.4409 94.1921 88.4409 88.2063C88.4409 85.4957 90.7609 83.4062 94.3823 83.4062C96.872 83.4062 98.9656 84.2533 100.493 85.7215L98.8524 87.8674C97.551 86.5686 95.7969 86.0039 94.0993 86.0039C92.4584 86.0039 91.3833 86.851 91.3833 88.0368C91.3833 91.0862 100.889 89.2227 100.889 95.2651C100.889 97.9757 98.9656 100.291 94.6086 100.291C91.553 100.291 89.3462 99.218 87.9316 97.6933Z'
        fill='white'
      />
      <path
        d='M119.054 100.009V86.2297H114.131V83.745H126.862V86.2297H121.94V100.009H119.054Z'
        fill='white'
      />
      <path
        d='M150.514 100.009L146.949 93.9097H144.064V100.009H141.178V83.745H148.364C151.589 83.745 153.739 85.8344 153.739 88.8273C153.739 91.7073 151.816 93.2885 149.835 93.6273L153.853 100.009H150.514ZM150.797 88.8273C150.797 87.2462 149.609 86.2862 148.024 86.2862H144.064V91.4815H148.024C149.609 91.425 150.797 90.4085 150.797 88.8273Z'
        fill='white'
      />
    </svg>
  );
};
