import { useEffect, useState } from 'react';
import { Key } from 'ts-key-enum';

export const useKeyPress = (
  targetKey?: string,
  condition?: (event: KeyboardEvent) => boolean
) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(event: KeyboardEvent) {
    if (condition && !condition(event)) {
      return;
    }
    if (event.key === targetKey || event.code === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (condition && !condition(event)) {
      return;
    }
    if (event.key === targetKey || event.code === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    if (!targetKey) {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    }

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};
