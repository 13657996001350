import { Button, ButtonProps } from '@mui/material';
import React, { FC, useEffect, useRef, RefObject } from 'react';
import { useKeyPress } from '../hooks/useKeyPress';
import { Key } from 'ts-key-enum';

export interface KeyTriggeredButtonProps extends ButtonProps {
  keyTrigger?: Key;
  childRef?: RefObject<HTMLButtonElement>;
}

const triggerMouseEvent = (node: HTMLButtonElement, eventType: string) => {
  const clickEvent = document.createEvent('MouseEvents');
  clickEvent.initEvent(eventType, true, true);
  node.dispatchEvent(clickEvent);
};

export const KeyTriggeredButton: FC<KeyTriggeredButtonProps> = ({
  keyTrigger,
  childRef,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const keyPress = useKeyPress(keyTrigger);

  useEffect(() => {
    const localRef = childRef || ref;
    if (localRef.current && keyPress) {
      triggerMouseEvent(localRef.current, 'mouseover');
      triggerMouseEvent(localRef.current, 'mousedown');
      triggerMouseEvent(localRef.current, 'mouseup');
      triggerMouseEvent(localRef.current, 'click');
    }
  }, [keyPress]);

  return <Button ref={childRef || ref} {...props} />;
};
